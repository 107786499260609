import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {


  products: any = [];
  lnParams:any = {};
  userData: any;
  companyID:any;

  constructor(public router: Router) {
    var userData = JSON.parse(localStorage.getItem('userData'));
    if(userData){
      this.userData = userData;
    }
  }

  logout(){
    this.userData = null;
    this.companyID = null;
    localStorage.clear();
      this.router.navigate(['/auth/' + this.lnParams.companyCode + '/' + this.lnParams.linkId]);
  }

}
