import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  companyID:any;

  constructor(public user:UserService,public router:Router,public route:ActivatedRoute){


  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.user.lnParams = route.params;
      if(this.user.userData){
          return true;
      }
      else
      this.router.navigate(['/auth/'+ this.user.lnParams.companyCode + '/' + this.user.lnParams.linkId]);

  }

}
